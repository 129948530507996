
    import Vue from "vue";
    import Component from "vue-class-component";
    import apiClient from "@/utilities/ApiClient";
    import store from "@/store/store";
    import Utils from "@/utilities/Utils";
    import { ILanguage, Language } from "@/model/Language";

    @Component({
        components: { 

        }
    })

    export default class LandingPage extends Vue {

        async mounted(): Promise<void> {
            this.guid = this.$router.currentRoute.params.guid;
            this.retailer = parseInt(this.$router.currentRoute.params.retailer);

            await store.dispatch("setRetailer", this.retailer)

            await this.loadLanguages();
        }

        //
        // -- properties
        //

        guid = Utils.emptyGuidValue;
        retailer = 0;
        private languages: Array<ILanguage> = [];

        //
        // -- methods
        //

        private async loadLanguages() {
            const response = await apiClient.get(`/api/language/loadLanguages?retailer=${this.retailer}&key=${this.guid}`);
            this.languages.push(...response.map((v: ILanguage) => new Language(v)));

            await store.dispatch("setLanguages", this.languages)
            this.$router.push("/");
        }
    }
    
