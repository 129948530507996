import { mapData } from "@/utilities/DataMapping";

export interface ILanguage {
    itemDescription: string;
    itemSubTextHtml: string;
    languageRef: string;
    additionalCareDescription: string;
}

export class Language implements ILanguage {

    constructor(data?: ILanguage) {
        if (data) this.update(data);
    }

    update(data: ILanguage) {
        mapData(data, { root: () => this });
    }

    itemDescription: string = "";
    itemSubTextHtml: string = "";
    languageRef: string = "";
    additionalCareDescription: string = "";
}